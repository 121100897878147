<template>
  <v-app id="login">
    <v-main>
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col cols="12" md="5">
            <v-img :src="logo" max-height="250" contain />
          </v-col>
          <v-col cols="12" md="5">
            <template v-if="state == 1">
              <v-card class="mx-auto" elevation="0" max-width="500">
                <v-card-title class="justify-center main-title"> Inicio de sesión </v-card-title>
                <v-card-title class="justify-center mb-3 sub-title"> Plataforma de campañas SMS </v-card-title>
                <v-card-text>
                  <v-form ref="formLogin" lazy-validation @keyup.enter.native="login()">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="forms.login.username"
                          label="Usuario"
                          :rules="rules.username"
                          :disabled="loading"
                          outlined
                          required
                          autocomplete="off"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="forms.login.password"
                          type="password"
                          label="Contraseña"
                          :rules="rules.password"
                          :disabled="loading"
                          outlined
                          required
                          autocomplete="off"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row justify="center">
                    <v-btn
                      rounded
                      text
                      small
                      elevation="0"
                      color="primary"
                      class="mx-2 text-normal"
                      :disabled="loading"
                      @click="updateState(2)"
                      >Recuperar contraseña</v-btn
                    >
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    large
                    block
                    color="primary"
                    class="white--text text-normal"
                    :loading="loading"
                    @click="login()"
                    >Iniciar sesión</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
            <template v-else-if="state == 2">
              <v-card class="mx-auto" elevation="0" max-width="500">
                <v-card-title class="justify-center mb-3 main-title"> Recuperar contraseña </v-card-title>
                <v-card-title class="justify-center mb-3 sub-title"> Plataforma de campañas SMS </v-card-title>
                <v-card-text>
                  <v-form ref="formForgot" lazy-validation @submit.prevent>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="forms.forgot.username"
                          label="Usuario"
                          :rules="rules.username"
                          :disabled="loading"
                          outlined
                          required
                          autocomplete="off"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row align="center" justify="space-around" class="pa-3">
                    <v-btn
                      large
                      block
                      rounded
                      outlined
                      class="mb-3 text-normal"
                      :disabled="loading"
                      @click="updateState(1)"
                      >Volver</v-btn
                    >
                    <v-btn
                      large
                      block
                      rounded
                      color="primary"
                      class="text-normal"
                      :loading="loading"
                      @click="recovery()"
                      >Enviar solicitud</v-btn
                    >
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-col>
        </v-row>
        <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
          {{ msgError }}
        </v-snackbar>
        <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
          {{ msgSuccess }}
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "login",
  data: () => ({
    host: process.env.VUE_APP_API_SERVER,
    name: process.env.VUE_APP_NAME,
    logo: require("@/assets/logo.png"),
    path: "/pub/login",
    success: false,
    msgSuccess: "",
    paths: {
      recoverPassword: "/pub/user/recover/password",
    },
    loading: false,
    error: false,
    msgError: "",
    state: 1,
    forms: {
      login: {
        username: "",
        password: "",
      },
      forgot: {
        username: "",
      },
    },
    rules: {
      username: [
        (v) => !!v || "",
        (v) => (v && v.length <= 320) || "",
        (v) =>
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "",
      ],
      password: [(v) => !!v || "", (v) => (v && v.length <= 60) || ""],
    },
  }),
  methods: {
    updateState(id) {
      if (this.state == 1) {
        this.$refs.formLogin.reset();
      } else if (this.state == 2) {
        this.$refs.formForgot.reset();
      }
      for (let key in this.forms.login) {
        this.forms.login[key] = "";
      }
      for (let key in this.forms.forgot) {
        this.forms.forgot[key] = "";
      }
      this.state = id;
    },
    login() {
      if (this.$refs.formLogin.validate()) {
        this.loading = true;
        let data = {
          username: this.forms.login.username,
          password: this.forms.login.password,
        };
        this.axios
          .post(this.host + this.path, { data })
          .then((response) => {
            let res = response.data;
            this.$session.start();
            this.$session.set("jwt", res.token);
            this.$router.push("/home");
          })
          .catch((error) => {
            this.error = true;
            if (error.response) {
              this.msgError = error.response.data.message;
            } else {
              this.msgError = "Servicio no disponible. Intente más tarde";
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = "Los datos son incorrectos";
      }
    },
    recovery() {
      if (this.$refs.formForgot.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.forgot));
        this.axios
          .post(this.host + this.paths.recoverPassword, { data })
          .then((response) => {
            this.updateState(1);
            this.success = true;
            this.msgSuccess = "Solicitud enviada exitosamente";
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = "La información es incorrecta";
      }
    },
  },
};
</script>

<style scoped>
.vl {
  margin-left: 50px;
  border-left: 1px solid black;
  height: 300px;
}
.main-title {
  font-size: 38px;
  font-weight: bold;
  color: black;
}
.sub-title {
  font-size: 18px;
  color: black;
}
.text-normal {
  text-transform: none !important;
}
</style>
